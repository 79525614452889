import React from 'react'

import { NpmRepeatOrderPage } from '../local_modules'

import type { OrderType } from '../local_modules/types/RepeatOrder'
import type { PageProps } from 'gatsby'

function RepeatOrderPage({ location }: PageProps) {
  const params = new URLSearchParams(location.search)
  const orderId = params.get('orderId') ?? ''
  const type = (params.get('type') ?? '') as OrderType

  return <NpmRepeatOrderPage orderId={orderId} type={type} />
}

export default RepeatOrderPage
